import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.css';

import './../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import './../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css';
import './../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';

import "./../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";

import 'ag-grid-angular';

//import "./../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js";

//import * as jQuery from './../node_modules/jquery/dist/jquery.min.js';
//import './../node_modules/jquery-ui/ui/widgets/datepicker';
//import './../node_modules/jquery-ui/ui/widgets/dialog';

//import "./../../MeestMVC/Scripts/libs/jSignature.min.js";
//import "./../node_modules/jsignature/src/jSignature.js";
